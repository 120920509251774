<!-- Start Main Container Area -->
<div class="main-container">
    <aside id="placid-aside" class="placid-aside bg-0f1d22">
        <div class="logo">
            <a routerLink="/">
                <img src="assets/img/logo.svg" alt="image">
            </a>
        </div>

        <div class="d-table">
            <div class="d-table-cell">
                <ul class="navbar-nav">
                    <li class="nav-item"><a class="nav-link active" href="#home">Home</a></li>
                    <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                    <!-- <li class="nav-item"><a class="nav-link" href="#services">Services</a></li> -->
                    <li class="nav-item"><a class="nav-link" href="#portfolio">Portfolio</a></li>
                    <!-- <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li> -->
                    <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
                </ul>
            </div>
        </div>

        <div class="aside-footer">
            <a href="#contact" class="default-btn">Hire Me</a>

            <ul class="social">
                <li><a href="https://www.linkedin.com/in/flavio-mueller-ds/" target="_blank"><i
                            class="ri-linkedin-box-line"></i></a></li>
            </ul>
        </div>

        <div class="line"></div>
    </aside>

    <div id="main-content" class="main-content">

        <div class="responsive-burger-menu">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>

        <section class="works-details-area bg-0f1d22 ptb-120">
            <div class="container">
                <div class="row">
                    <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="works-details-image">
                            <img src="assets/img/blog/img1.jpg" alt="projects">

                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="popup-youtube"><i
                                    class="ri-play-line"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="works-details-image">
                            <img src="assets/img/blog/img2.jpg" alt="projects">

                            <a href="assets/img/blog/img2.jpg" class="popup-btn"><i class="ri-add-line"></i></a>
                        </div>
                    </div> -->

                    <div class="col-lg-12 col-md-12">
                        <div class="works-details-desc">
                            <h3>The Energy Platform of the Future</h3>

                            <p>During my time at Virtual Global Trading I was working on the VGT Platform. This was the
                                main product of the company and consitet of mulitple modules which could be added based
                                on the desires of the client. I worked as a Full-Stack Developer where i planned and
                                developed mulitple Modules including P2P-Energy Trading, Direct Marketing, Ortsnetz and
                                more.</p>

                            <div class="features-text">
                                <h4><i class="ri-check-line"></i> Core Development</h4>
                                <p>I worked on the core funcitonalites of those modules. Inlcuing the high demand parts
                                    which neeeded to be optimized to reach our target throughput.</p>
                            </div>

                            <div class="features-text">
                                <h4><i class="ri-check-line"></i> Modular</h4>
                                <p>The platform is fully modular and customizable, so all the funcitonalites needed to
                                    be planned and implemented with those two restricitons in mind.</p>
                            </div>

                            <p>We are still working hard on this platform to make it better every day. This is done by
                                adding new modules, improving performance and doing the occasional maintanance.</p>

                            <div class="works-details-info">
                                <div class="single-info-box">
                                    <h4>Client</h4>
                                    <span>VGT AG</span>
                                </div>

                                <div class="single-info-box">
                                    <h4>Category</h4>
                                    <span>Development, Optimization</span>
                                </div>

                                <div class="single-info-box">
                                    <h4>Date</h4>
                                    <span>February 28, 2022</span>
                                </div>

                                <!-- <div class="single-info-box">
                                    <h4>Share</h4>
                                    <ul class="social">
                                        <li><a href="#" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="ri-twitter-line"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                                    </ul>
                                </div> -->

                                <div class="single-info-box">
                                    <a href="https://vgt.energy" target="_blank" class="default-btn">Live Preview</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <footer class="footer-area">
            <div class="container">
                <div class="footer-content">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo.svg" alt="image">
                        </a>
                    </div>
                    <div class="footer-contact-info">
                        <ul>
                            <li><i class="ri-map-pin-line"></i> <a href="#" target="_blank">Schweizistrasse 23, 5102
                                    Rupperswil, CH</a></li>
                            <li><i class="ri-phone-line"></i> <a href="tel:+41768023010">(+41) 76 802 30 10</a></li>
                            <li><i class="ri-mail-line"></i> <a
                                    href="mailto:info@flavio-mueller.ch">flavio-mueller.ch</a></li>
                        </ul>
                    </div>
                    <ul class="social">
                        <!-- <li><a href="#" target="_blank">Facebook</a></li>
                        <li><a href="#" target="_blank">Twitter</a></li> -->
                        <li><a href="https://www.linkedin.com/in/flavio-mueller-ds/" target="_blank">Linkedin</a></li>
                        <!-- <li><a href="#" target="_blank">Dribbble</a></li>
                        <li><a href="#" target="_blank">Instagram</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="footer-bottom-area">
                <div class="container">
                    <p>© Flavio Müller</p>
                </div>
                <div class="line"></div>
            </div>
        </footer>
    </div>
</div>
<!-- End Main Container Area -->