import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-road-safety',
  templateUrl: './road-safety.component.html',
  styleUrls: ['./road-safety.component.scss']
})
export class RoadSafetyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
