<!-- Start Main Container Area -->
<div class="main-container">
    <aside id="placid-aside" class="placid-aside bg-0f1d22">
        <div class="logo">
            <a routerLink="/">
                <img src="assets/img/logo.svg" alt="image">
            </a>
        </div>

        <div class="d-table">
            <div class="d-table-cell">
                <ul class="navbar-nav">
                    <li class="nav-item"><a class="nav-link active" href="#home">Home</a></li>
                    <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                    <!-- <li class="nav-item"><a class="nav-link" href="#services">Services</a></li> -->
                    <li class="nav-item"><a class="nav-link" href="#portfolio">Portfolio</a></li>
                    <!-- <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li> -->
                    <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
                </ul>
            </div>
        </div>

        <div class="aside-footer">
            <a href="#contact" class="default-btn">Hire Me</a>

            <ul class="social">
                <li><a href="https://www.linkedin.com/in/flavio-mueller-ds/" target="_blank"><i
                            class="ri-linkedin-box-line"></i></a></li>
            </ul>
        </div>

        <div class="line"></div>
    </aside>

    <div id="main-content" class="main-content">

        <div class="responsive-burger-menu">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>

        <section class="works-details-area bg-0f1d22 ptb-120">
            <div class="container">
                <div class="row">
                    <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="works-details-image">
                            <img src="assets/img/blog/img1.jpg" alt="projects">

                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="popup-youtube"><i
                                    class="ri-play-line"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="works-details-image">
                            <img src="assets/img/blog/img2.jpg" alt="projects">

                            <a href="assets/img/blog/img2.jpg" class="popup-btn"><i class="ri-add-line"></i></a>
                        </div>
                    </div> -->

                    <div class="col-lg-12 col-md-12">
                        <div class="works-details-desc">
                            <h3>Shining Light into the European Energy Market</h3>

                            <p>During my studies at the FHNW, Ronny Schneeberger and I worked on a project where we
                                researched the impact of the oil price on the carbon emission on european countries.
                                This was done using mulitple data sources like ENTSO-E. The project was completed in one
                                Semester with the best grade possible. Additionally the project was chosen to be
                                presented a the Stapferhaus in Lenzburg during a exhibition about energy.
                            </p>

                            <div class="features-text">
                                <h4><i class="ri-check-line"></i> Data Analysis</h4>
                                <p>The main part was analysing all the data from the different sources.</p>
                            </div>

                            <div class="features-text">
                                <h4><i class="ri-check-line"></i> Compelling Results</h4>
                                <p>The results were brought to the client in form of a data story.</p>
                            </div>

                            <p>What we saw during the analysis was, that it really depends on the country. While the
                                carbon emissions of some countries correlate pretty heavily with the oil price, other
                                are more independant. The main factor determening if the carbon emissions from a country
                                are linked to the oil price is the energy mix of set country. When a nation is still
                                using a lot of fossil fuels like oil, coal and gas for energy generation we tend to see
                                a greater correlation.</p>

                            <div class="works-details-info">
                                <div class="single-info-box">
                                    <h4>Client</h4>
                                    <span>Daniel Claus</span>
                                </div>

                                <div class="single-info-box">
                                    <h4>Category</h4>
                                    <span>Data Analysis, Visualisation</span>
                                </div>

                                <div class="single-info-box">
                                    <h4>Date</h4>
                                    <span>June 28, 2022</span>
                                </div>

                                <!-- <div class="single-info-box">
                                    <h4>Share</h4>
                                    <ul class="social">
                                        <li><a href="#" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="ri-twitter-line"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                                    </ul>
                                </div>

                                <div class="single-info-box">
                                    <a href="#" class="default-btn">Live Preview</a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <footer class="footer-area">
            <div class="container">
                <div class="footer-content">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo.svg" alt="image">
                        </a>
                    </div>
                    <div class="footer-contact-info">
                        <ul>
                            <li><i class="ri-map-pin-line"></i> <a href="#" target="_blank">Schweizistrasse 23, 5102
                                    Rupperswil, CH</a></li>
                            <li><i class="ri-phone-line"></i> <a href="tel:+41768023010">(+41) 76 802 30 10</a></li>
                            <li><i class="ri-mail-line"></i> <a
                                    href="mailto:info@flavio-mueller.ch">flavio-mueller.ch</a></li>
                        </ul>
                    </div>
                    <ul class="social">
                        <!-- <li><a href="#" target="_blank">Facebook</a></li>
                        <li><a href="#" target="_blank">Twitter</a></li> -->
                        <li><a href="https://www.linkedin.com/in/flavio-mueller-ds/" target="_blank">Linkedin</a></li>
                        <!-- <li><a href="#" target="_blank">Dribbble</a></li>
                        <li><a href="#" target="_blank">Instagram</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="footer-bottom-area">
                <div class="container">
                    <p>© Flavio Müller</p>
                </div>
                <div class="line"></div>
            </div>
        </footer>
    </div>
</div>
<!-- End Main Container Area -->