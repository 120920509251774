<!-- Start Main Container Area -->
<div class="main-container">
    <aside id="placid-aside" class="placid-aside bg-0f1d22">
        <div class="logo">
            <a routerLink="/">
                <img src="assets/img/logo.svg" alt="image">
            </a>
        </div>
        <div class="d-table">
            <div class="d-table-cell">
                <ul class="navbar-nav">
                    <li class="nav-item"><a class="nav-link active" href="#home">Home</a></li>
                    <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                    <!-- <li class="nav-item"><a class="nav-link" href="#services">Services</a></li> -->
                    <li class="nav-item"><a class="nav-link" href="#portfolio">Portfolio</a></li>
                    <!-- <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li> -->
                    <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
                </ul>
            </div>
        </div>

        <div class="aside-footer">
            <a href="#contact" class="default-btn">Hire Me</a>

            <ul class="social">
                <li><a href="https://www.linkedin.com/in/flavio-mueller-ds/" target="_blank"><i
                            class="ri-linkedin-box-line"></i></a></li>
            </ul>
        </div>

        <div class="line"></div>
    </aside>

    <div id="main-content" class="main-content">

        <div class="responsive-burger-menu">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>

        <section class="works-details-area bg-0f1d22 ptb-120">
            <div class="container">
                <div class="row">
                    <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="works-details-image">
                            <img src="assets/img/blog/img1.jpg" alt="projects">

                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="popup-youtube"><i
                                    class="ri-play-line"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="works-details-image">
                            <img src="assets/img/blog/img2.jpg" alt="projects">

                            <a href="assets/img/blog/img2.jpg" class="popup-btn"><i class="ri-add-line"></i></a>
                        </div>
                    </div> -->

                    <div class="col-lg-12 col-md-12">
                        <div class="works-details-desc">
                            <h3>Optimizing High Throughput Cloud Tasks</h3>

                            <p>During my time at VGT we were pushing the limits of our databases and our cloud services.
                                When importing a lot of data, our applications and the servers they were running on were
                                not fast enough to handle the massive amount of data. So we needed a new way of
                                processing these imports.</p>

                            <div class="features-text">
                                <h4><i class="ri-check-line"></i> Cloud First</h4>
                                <p>The approach we took was based on the cloud first pricipal and usitlized the massive
                                    parallelization which is possible in the cloud.</p>
                            </div>

                            <div class="features-text">
                                <h4><i class="ri-check-line"></i> Quick implementation</h4>
                                <p>With stress built up by our clients we could not redevelop our whole process, so we
                                    needed to find a time effective solution.</p>
                            </div>

                            <p>To utilize the parallelization potential the cloud offers. I implemented a framework
                                which is built on Azure Durable Functions and Azure Service Bus to handle mulitple data
                                input streams at once, while keeping track of the different states.</p>

                            <div class="works-details-info">
                                <div class="single-info-box">
                                    <h4>Client</h4>
                                    <span>VGT AG</span>
                                </div>

                                <div class="single-info-box">
                                    <h4>Category</h4>
                                    <span>Cloud, Parallelization</span>
                                </div>

                                <div class="single-info-box">
                                    <h4>Date</h4>
                                    <span>August 13, 2022</span>
                                </div>
                                <!-- 
                                <div class="single-info-box">
                                    <h4>Share</h4>
                                    <ul class="social">
                                        <li><a href="#" target="_blank"><i class="ri-facebook-fill"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="ri-twitter-line"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="ri-instagram-line"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="ri-linkedin-fill"></i></a></li>
                                    </ul>
                                </div> -->

                                <!-- <div class="single-info-box">
                                    <a href="#" class="default-btn">Live Preview</a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <footer class="footer-area">
            <div class="container">
                <div class="footer-content">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo.svg" alt="image">
                        </a>
                    </div>
                    <div class="footer-contact-info">
                        <ul>
                            <li><i class="ri-map-pin-line"></i> <a href="#" target="_blank">Schweizistrasse 23, 5102
                                    Rupperswil, CH</a></li>
                            <li><i class="ri-phone-line"></i> <a href="tel:+41768023010">(+41) 76 802 30 10</a></li>
                            <li><i class="ri-mail-line"></i> <a
                                    href="mailto:info@flavio-mueller.ch">flavio-mueller.ch</a></li>
                        </ul>
                    </div>
                    <ul class="social">
                        <!-- <li><a href="#" target="_blank">Facebook</a></li>
                        <li><a href="#" target="_blank">Twitter</a></li> -->
                        <li><a href="https://www.linkedin.com/in/flavio-mueller-ds/" target="_blank">Linkedin</a></li>
                        <!-- <li><a href="#" target="_blank">Dribbble</a></li>
                        <li><a href="#" target="_blank">Instagram</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="footer-bottom-area">
                <div class="container">
                    <p>© Flavio Müller</p>
                </div>
                <div class="line"></div>
            </div>
        </footer>
    </div>
</div>
<!-- End Main Container Area -->