<!-- Start Main Container Area -->
<div class="main-container">
    <aside id="placid-aside" class="placid-aside bg-0f1d22">
        <div class="logo">
            <a routerLink="/">
                <img src="assets/img/logo.svg" alt="image">
            </a>
        </div>
        <div class="d-table">
            <div class="d-table-cell">
                <ul class="navbar-nav">
                    <li class="nav-item"><a class="nav-link active" href="#home">Home</a></li>
                    <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
                    <!-- <li class="nav-item"><a class="nav-link" href="#services">Services</a></li> -->
                    <li class="nav-item"><a class="nav-link" href="#portfolio">Portfolio</a></li>
                    <!-- <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li> -->
                    <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
                </ul>
            </div>
        </div>
        <div class="aside-footer">
            <a href="#contact" class="default-btn">Hire Me</a>
            <ul class="social">
                <li><a href="https://www.linkedin.com/in/flavio-mueller-ds/" target="_blank"><i
                            class="ri-linkedin-box-line"></i></a></li>
            </ul>
        </div>
        <div class="line"></div>
    </aside>

    <div id="main-content" class="main-content">
        <div class="responsive-burger-menu">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>

        <div class="main-banner banner-bg1 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <span class="sub-title">Hello I'am</span>
                            <img class="main-img" src="assets/img/flaviomueller.svg" alt="main logo" />
                            <h6>I’am a <span>Data Specialist</span></h6>
                        </div>
                    </div>
                </div>
            </div>

            <div class="scroll-btn">
                <a href="#about">
                    <span class="mouse">
                        <span></span>
                    </span>
                </a>
            </div>
        </div>

        <section id="about" class="about-area ptb-120">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">About Me</span>
                    <h2>Generating insights through efficient information processing.</h2>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-12">
                        <div class="about-content">
                            <p>Too much data to handle? I got you covered. From data collection to aggregation and
                                analysis, I provide the serivces needed to handle any amount of data. With state of the
                                art machine learning models, I will help you getting the information you want and need.
                            </p>

                            <div class="tab about-list-tab">
                                <ul class="tabs">
                                    <li>Skills</li>
                                    <li>Experience</li>
                                    <li>Education</li>
                                    <li>Certification</li>
                                </ul>

                                <div class="tab-content">
                                    <div class="tabs-item">
                                        <div class="skills-area">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">Communication</h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box"><span class="count-text"
                                                                        data-speed="2000" data-stop="82">0</span>%</div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="82"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">Design</h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box"><span class="count-text"
                                                                        data-speed="2000" data-stop="75">0</span>%</div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="75"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">Project Management</h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box"><span class="count-text"
                                                                        data-speed="2000" data-stop="77">0</span>%</div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="77"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">Data Engineering</h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box"><span class="count-text"
                                                                        data-speed="2000" data-stop="85">0</span>%</div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="85"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">Software Development</h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box"><span class="count-text"
                                                                        data-speed="2000" data-stop="94">0</span>%</div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="94"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">Visualisation</h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box"><span class="count-text"
                                                                        data-speed="2000" data-stop="75">0</span>%</div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="75"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">Machine Learning</h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box"><span class="count-text"
                                                                        data-speed="2000" data-stop="80">0</span>%</div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="80"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="skills-item">
                                                        <div class="skills-header">
                                                            <h3 class="skills-title">Presentation</h3>

                                                            <div class="skills-percentage">
                                                                <div class="count-box"><span class="count-text"
                                                                        data-speed="2000" data-stop="85">0</span>%</div>
                                                            </div>
                                                        </div>

                                                        <div class="skills-bar">
                                                            <div class="bar-inner">
                                                                <div class="bar progress-line" data-width="85"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tabs-item">
                                        <div class="experience-area">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <span>Aug. 2015 - Jun. 2019 (Apprentice)</span>
                                                        <h3>FHNW Windisch, Switzerland</h3>
                                                        <p>Apprenticeship as a software developer. Mainly working on
                                                            frontend web development projects.</p>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <span>Jul. 2019 - Nov. 2020 (Sergeant)</span>
                                                        <h3>Swiss Armend Forces</h3>
                                                        <p>Group leader in a electronic warfare batallion. Resposible
                                                            for 10-20 Soldiers.</p>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <span>Nov. 2020 - Jul. 2021 (Software Developer)</span>
                                                        <h3>Institue of interactive Technologies</h3>
                                                        <p>Developed software for research projects. Mostly working on
                                                            frontend web projects.</p>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-experience-box">
                                                        <span>Aug. 2021 - Today (Data Scientist)</span>
                                                        <h3>Virtual Global Trading AG</h3>
                                                        <p>Training and validating models for energy consumption and
                                                            generation forecasts.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tabs-item">
                                        <div class="education-area">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-education-box">
                                                        <span>2015 - 2019</span>
                                                        <h3>BBBaden</h3>
                                                        <p>vocational baccalaureate</p>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-education-box">
                                                        <span>2015 - 2019 (Software Developemnt)</span>
                                                        <h3>BBBaden</h3>
                                                        <p>Professional school, part of the Apprenticeship.</p>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6 col-sm-6">
                                                    <div class="single-education-box">
                                                        <span>2020 - Today (Bachelor's Degree)</span>
                                                        <h3>FHNW School of Engineering</h3>
                                                        <p>Bachelor of Science in Data Science. Studying part time.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tabs-item">
                                        <div class="certification-area">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-certification-box">
                                                        <img src="assets/img/certification/img1.png" alt="image">
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-certification-box">
                                                        <img src="assets/img/certification/img2.png" alt="image">
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                                                    <div class="single-certification-box">
                                                        <img src="assets/img/certification/img3.png" alt="image">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <div class="about-image">
                            <img src="assets/img/portrait.jpeg" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- <section id="services" class="services-area pt-120 pb-90 bg-0f1d22">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Services</span>
                    <h2>Hand curated Offerings</h2>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <i class="flaticon-result"></i>
                            </div>
                            <h3>Data Analysis</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            <a routerLink="/services-details" class="read-more-btn">Read More<i
                                    class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <i class="flaticon-email"></i>
                            </div>
                            <h3>Data Strategies</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            <a routerLink="/services-details" class="read-more-btn">Read More<i
                                    class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <i class="flaticon-content"></i>
                            </div>
                            <h3>Visualization</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            <a routerLink="/services-details" class="read-more-btn">Read More<i
                                    class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <i class="flaticon-education"></i>
                            </div>
                            <h3>Potential Analysis</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            <a routerLink="/services-details" class="read-more-btn">Read More<i
                                    class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <i class="flaticon-analysis"></i>
                            </div>
                            <h3>Insight Generation</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            <a routerLink="/services-details" class="read-more-btn">Read More<i
                                    class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-services-box">
                            <div class="icon">
                                <i class="flaticon-team"></i>
                            </div>
                            <h3>Data Agreements</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            <a routerLink="/services-details" class="read-more-btn">Read More<i
                                    class="ri-arrow-right-s-line"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

        <section id="portfolio" class="works-area pt-120 pb-90 bg-0f1d22">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Work</span>
                    <h2>Past Projects</h2>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="single-works-box">
                            <div class="works-image">
                                <img src="assets/img/works/img1.jpg" alt="image">
                                <div class="overlay"></div>
                                <a routerLink="/energy-markets" class="link-btn"></a>
                            </div>

                            <div class="works-content">
                                <h3><a routerLink="/energy-markets">Energy Markets</a></h3>
                                <ul>
                                    <li><a href="#">Market Design</a></li>
                                    <li><a href="#">Cloud</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-8 col-sm-12">
                        <div class="single-works-box">
                            <div class="works-image">
                                <img src="assets/img/works/img2.jpg" alt="image">
                                <div class="overlay"></div>
                                <a routerLink="/energy-platform" class="link-btn"></a>
                            </div>

                            <div class="works-content">
                                <h3><a routerLink="/energy-platform">Energy Platform</a></h3>
                                <ul>
                                    <li><a href="#">Data Engineering</a></li>
                                    <li><a href="#">Optimization</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>



                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-works-box">
                            <div class="works-image">
                                <img src="assets/img/works/img4.jpg" alt="image">
                                <div class="overlay"></div>
                                <a routerLink="/energy-market-research" class="link-btn"></a>
                            </div>

                            <div class="works-content">
                                <h3><a routerLink="/energy-market-research">Energy Market Research</a></h3>
                                <ul>
                                    <li><a href="#">Data Analysis</a></li>
                                    <li><a href="#">Visualisation</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-works-box">
                            <div class="works-image">
                                <img src="assets/img/works/img3.jpg" alt="image">
                                <div class="overlay"></div>
                                <a routerLink="/cloud-optimization" class="link-btn"></a>
                            </div>

                            <div class="works-content">
                                <h3><a routerLink="/cloud-optimization">Cloud Optimization</a></h3>
                                <ul>
                                    <li><a href="#">Cloud</a></li>
                                    <li><a href="#">Parallelization</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div class="single-works-box">
                            <div class="works-image">
                                <img src="assets/img/works/img5.jpg" alt="image">
                                <div class="overlay"></div>
                                <a routerLink="/road-safety" class="link-btn"></a>
                            </div>

                            <div class="works-content">
                                <h3><a routerLink="/road-safety">Road Safety Estimations</a></h3>
                                <ul>
                                    <li><a href="#">Stochastics</a></li>
                                    <li><a href="#">Simulations</a></li>
                                </ul>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </section>

        <!-- <section class="feedback-area">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-5 col-md-12">
                        <div class="feedback-content jarallax" data-jarallax='{"speed": 0.3}'>
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="section-title">
                                        <span class="sub-title">Testimonials</span>
                                        <h2>What my clients say about me</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="feedback-slides-content bg-0f1d22">
                            <div class="feedback-slides owl-carousel owl-theme">
                                <div class="single-feedback-item">
                                    <i class="ri-double-quotes-r"></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt
                                        ut labore et dolore magna aliqua. eiusmod Quis ipsum suspendisse ultrices
                                        gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                    <div class="client-info">
                                        <h3>Jason Statham</h3>
                                        <span>Web Developer</span>
                                    </div>
                                </div>

                                <div class="single-feedback-item">
                                    <i class="ri-double-quotes-r"></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt
                                        ut labore et dolore magna aliqua. eiusmod Quis ipsum suspendisse ultrices
                                        gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                    <div class="client-info">
                                        <h3>Sarah Taylor</h3>
                                        <span>Web Designer</span>
                                    </div>
                                </div>

                                <div class="single-feedback-item">
                                    <i class="ri-double-quotes-r"></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt
                                        ut labore et dolore magna aliqua. eiusmod Quis ipsum suspendisse ultrices
                                        gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                                    <div class="client-info">
                                        <h3>David Warner</h3>
                                        <span>PHP Developer</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="my-clients-area ptb-120">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Clients</span>
                    <h2>My Honoranle Clients</h2>
                </div>

                <div class="row">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img1.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img2.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img3.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img4.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img5.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img6.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img7.png" alt="image">
                            </a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-4 col-sm-6 col-6">
                        <div class="single-clients">
                            <a href="#">
                                <img src="assets/img/clients/img8.png" alt="image">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <section class="hire-me-area ptb-120">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-12">
                        <div class="hire-me-content">
                            <span class="sub-title">Interested?</span>
                            <h2>I am ready to hear your challanges.</h2>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-12">
                        <div class="hire-me-btn">
                            <a href="#contact" class="default-btn">Hire Me</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- <section id="blog" class="blog-area pt-120 pb-90">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Blog</span>
                    <h2>Editors Pick</h2>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/img1.jpg" alt="image">
                                </a>
                            </div>

                            <div class="post-content">
                                <ul class="post-tag">
                                    <li><a href="#">Wordpress</a></li>
                                    <li><a href="#">SEO</a></li>
                                    <li><a href="#">Marketing</a></li>
                                </ul>

                                <h3><a routerLink="/blog-details">How to improve your agency in 2020</a></h3>

                                <div class="post-meta">
                                    <ul>
                                        <li>By <a href="#">John</a></li>
                                        <li>21 February, 2020</li>
                                    </ul>
                                    <a routerLink="/blog-details" class="details-btn"><i
                                            class="ri-arrow-right-s-line"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/img2.jpg" alt="image">
                                </a>
                            </div>

                            <div class="post-content">
                                <ul class="post-tag">
                                    <li><a href="#">HTML</a></li>
                                    <li><a href="#">CSS</a></li>
                                </ul>

                                <h3><a routerLink="/blog-details">10 features your CMS absolutely needs</a></h3>

                                <div class="post-meta">
                                    <ul>
                                        <li>By <a href="#">John</a></li>
                                        <li>20 February, 2020</li>
                                    </ul>
                                    <a routerLink="/blog-details" class="details-btn"><i
                                            class="ri-arrow-right-s-line"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog/img3.jpg" alt="image">
                                </a>
                            </div>

                            <div class="post-content">
                                <ul class="post-tag">
                                    <li><a href="#">JS</a></li>
                                    <li><a href="#">PHP</a></li>
                                </ul>

                                <h3><a routerLink="/blog-details">The beginner's guide to CSS classes</a></h3>

                                <div class="post-meta">
                                    <ul>
                                        <li>By <a href="#">John</a></li>
                                        <li>19 February, 2020</li>
                                    </ul>
                                    <a routerLink="/blog-details" class="details-btn"><i
                                            class="ri-arrow-right-s-line"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

        <section id="contact" class="contact-area">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-5 col-md-12">
                        <div class="contact-content jarallax" data-jarallax='{"speed": 0.3}'>
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="section-title">
                                        <span class="sub-title">Let's talk</span>
                                        <h2>Get in Touch With Me</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="contact-form bg-0f1d22">
                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <input type="text" name="name" id="name" class="form-control"
                                                placeholder="Enter your name">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <input type="email" name="email" id="email" class="form-control"
                                                placeholder="Enter your email">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <textarea name="message" class="form-control" id="message" cols="30"
                                                rows="5" placeholder="Enter your message"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <footer class="footer-area">
            <div class="container">
                <div class="footer-content">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo.svg" alt="image">
                        </a>
                    </div>
                    <div class="footer-contact-info">
                        <ul>
                            <li><i class="ri-map-pin-line"></i> <a href="#" target="_blank">Schweizistrasse 23, 5102
                                    Rupperswil, CH</a></li>
                            <li><i class="ri-phone-line"></i> <a href="tel:+41768023010">(+41) 76 802 30 10</a></li>
                            <li><i class="ri-mail-line"></i> <a
                                    href="mailto:info@flavio-mueller.ch">flavio-mueller.ch</a></li>
                        </ul>
                    </div>
                    <ul class="social">
                        <!-- <li><a href="#" target="_blank">Facebook</a></li>
                        <li><a href="#" target="_blank">Twitter</a></li> -->
                        <li><a href="https://www.linkedin.com/in/flavio-mueller-ds/" target="_blank">Linkedin</a></li>
                        <!-- <li><a href="#" target="_blank">Dribbble</a></li>
                        <li><a href="#" target="_blank">Instagram</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="footer-bottom-area">
                <div class="container">
                    <p>© Flavio Müller</p>
                </div>
                <div class="line"></div>
            </div>
        </footer>
    </div>
</div>
<!-- End Main Container Area -->