import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-energy-markets',
  templateUrl: './energy-markets.component.html',
  styleUrls: ['./energy-markets.component.scss']
})
export class EnergyMarketsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
