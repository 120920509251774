import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { RoadSafetyComponent } from './components/pages/road-safety-estimations/road-safety.component';
import { EnergyMarketsComponent } from './components/pages/energy-markets/energy-markets.component';
import { EnergyPlatformComponent } from './components/pages/energy-platform/energy-platform.component';
import { CloudOptimizationComponent } from './components/pages/cloud-optimization/cloud-optimization.component';
import { EnergyMarketResearchComponent } from './components/pages/energy-market-research/energy-market-research.component';

const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'services-details', component: ServicesDetailsComponent},
    {path: 'road-safety', component: RoadSafetyComponent},
    {path: 'energy-market-research', component: EnergyMarketResearchComponent},
    {path: 'cloud-optimization', component: CloudOptimizationComponent},
    {path: 'energy-platform', component: EnergyPlatformComponent},
    {path: 'energy-markets', component: EnergyMarketsComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }