import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-energy-platform',
  templateUrl: './energy-platform.component.html',
  styleUrls: ['./energy-platform.component.scss']
})
export class EnergyPlatformComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
